.wordRack {
	padding: 0.25rem;
	font-size: 2rem;
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	height: 22rem;
	flex-grow: 1;
}
.message {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 0.25rem;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 1.75rem;
}
