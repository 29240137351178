.nav {
	border-bottom: solid 4px black;
	width: 100%;
	display: flex;
	height: var(--top-bar-height);
	background: var(--nav-bg);
	/* background: var(--blue); */
	* {
		flex-basis: 80px;
		font-weight: bold;
		padding: 0;
		font-size: 2rem;
		height: inherit;
		line-height: var(--top-bar-height);
		vertical-align: middle;
	}

	svg {
		height: 24px;
		width: 24px;
		padding-bottom: 6px;
	}
	/* &:after {
		content: " ";
		background: var(--blue);
		 /* width: var(--top-bar-height);
		width: 30%;
		height: var(--top-bar-height);
		position: absolute;
		top: 0;
		left: 0;
		clip-path: polygon(0 0, 0% 100%, 100% 0);

		/* z-index: 4; 
	} */
	h1 {
		justify-self: center;
		margin: 0 1rem;
		z-index: 2;
	}

	.spacer {
		flex-grow: 2;
	}

	ul {
		display: flex;
		flex-grow: 2;
		justify-content: flex-end;
		justify-self: flex-end;
	}

	li {
		font-size: 2rem;
		font-weight: 400;
		display: block;
		max-width: 40px;

		&:last-child {
			margin-right: 2rem;
		}
		a {
			width: 100%;
			height: 100%;
			display: block;
			text-align: center;
		}
	}
}
@media screen and (max-width: 600px) {
	.nav {
		font-size: 1.5rem;
		ul {
		}
		li {
			&:last-child {
				margin-right: 1rem;
			}
		}
		h1 {
			font-size: 1.5rem;
		}
		.spacer {
			display: none;
		}
	}
}
