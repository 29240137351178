#root,
body,
html,
.app {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: sans-serif;
}

li {
	list-style-type: none;
}
button,
a {
	cursor: pointer;
}
