.keyboard {
	padding: 0.5rem 0.5rem 0.5rem 0.5rem;
	text-transform: uppercase;
	font-weight: bold;
	height: 11.75rem;
}

.keyboard .hg-rows {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.keyboard .hg-row {
	flex-grow: 1;
}

.keyboard .hg-button {
	height: 100%;
}

.keyboard .grey {
	background-color: var(--grey);
}

.keyboard .orange {
	background-color: var(--orange);
}

.keyboard .green {
	background-color: var(--green);
}
