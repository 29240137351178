.endMarker {
	flex-basis: 0;
	flex-grow: 1;
	font-weight: bold;
	font-family: serif;
	font-size: 1.75rem;
	transition: all 300ms;
	text-align: right;
	margin-right: 4px;
	position: relative;
	min-width: 2rem;
	opacity: 1;
	color: var(--grey);

	&.hide {
		opacity: 0;
	}

	&.red {
		color: var(--red);
	}
	&.green {
		color: var(--green);
	}
}
