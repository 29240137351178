.help {
	/* margin: 8rem 8rem; */
	background-color: white;
	position: absolute;
	border: solid 4px black;
	height: auto;
	inset: var(--top-bar-height);
	h1 {
		margin: 1rem 1rem 2rem 1rem;
		font-weight: bold;
	}

	button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		border: none;
		background: unset;
		font-size: 1.5rem;
	}

	li {
		margin: 1rem;
	}
	ul {
		flex-direction: column;
	}
}
@media screen and (max-width: 600px) {
	.help {
		left: 1rem;
		right: 1rem;
		height: unset;
	}
}
