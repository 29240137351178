:root {
	--green: #6e9c78;
	--red: #c32222;
	--orange: #fe8a33;
	--border: #444;
	--grey: rgb(150, 150, 150);
	--blue: #86a1cc;
	--top-bar-height: 4rem;

	/* --nav-bg: var(--blue); */
}

@media (max-width: 600px) {
	:root {
		--top-bar-height: 3rem;
	}
}

/* c, #53a653, #4a934a, #408140, #376e37. Brand original color codes, colors palette. */

.toast {
	background: var(--orange);
	width: 100%;
	color: black;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.game-container {
	text-align: center;
	width: 375px;
	height: 36rem;
	margin: 4rem auto;
	border: solid 3px tan;
	border-radius: 1rem;
	overflow: hidden;
}

@media screen and (max-width: 375px) {
	.game-container {
		margin: 0;
		width: 100%;
		height: calc(100% - var(--top-bar-height));
		overflow: hidden;
		border: none;
	}
}

#game {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
